<template>
  <div>
    <alert v-if="loading" class="light-shadow" />
    <div
      v-if="!loading"
      class="
        card
        theme-card
        overflow-auto
        noPrint
        shadow-card
        mobile-tablet-no-card
        p-3
      "
    >
      <div class="rounded my-2" v-if="shouldDisplayMyRoomsFilter">
        <div class="custom-control custom-checkbox mx-2 mt-3">
          <input
            type="checkbox"
            class="custom-control-input"
            id="displayMyRoomsOnly"
            v-model="displayMyRoomsOnly"
            @change="getRoomsHelper"
          />
          <label
            class="custom-control-label mr-3 mb-3"
            for="displayMyRoomsOnly"
          >
            Display My Rooms Only
          </label>
        </div>
      </div>
      <alert
        class="my-3 mx-3 shadow-card"
        :hideLoader="true"
        v-if="rooms.length == 0"
      >
        No Rooms To List, Create New One Using
        <span class="font-weight-bold">Create New Room</span>
        Button.
      </alert>
      <div
        class="
          card
          mb-0
          shadow-card
          table-container
          mobile-tablet-no-card
          p-2 p-md-0
        "
        v-if="rooms.length > 0"
      >
        <table
          class="
            table table-sm table-striped
            mb-0
            responsive-mobile-tablet-table
          "
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Room Name</th>
              <th>Room Size</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Host Link</th>
              <th>Participant Link (To Share)</th>
              <th>Room Recordings</th>
              <th v-if="loggedInUser.isAdmin">Created By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="room in rooms" :key="room.id">
              <td>
                <b class="mobile-tablet-only mr-2">ID: </b>
                {{ room.id }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Room Name: </b>
                {{ room.room_name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Room Size: </b>
                {{ room.room_mode == "group" ? "2-200 people" : "2-4 people" }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Start Date: </b>
                {{ prettyDate(room.start_date, true) }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">End Date: </b>
                <span
                  :class="{
                    'text-danger': isExpired(room),
                  }"
                >
                  {{ prettyDate(room.end_date, true) }}
                </span>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Host Link: </b>
                <div v-if="isExpired(room)">
                  <span class="text-danger">Room Expired</span>
                </div>
                <div v-else>
                  <button
                    v-if="!room.host_link_key"
                    class="btn btn-outline-blue text-nowrap"
                    @click="getMeetingHostURL(room.id)"
                  >
                    <span
                      v-if="getRoomLoading == room.id"
                      class="fa fa-spin fa-circle-notch"
                    ></span>
                    <span v-else> Generate Meeting Host Link</span>
                  </button>
                  <div v-else>
                    <a
                      :href="getURL(room)"
                      target="_blank"
                      class="btn btn-outline-blue text-nowrap mr-1"
                    >
                      Open Host Link
                    </a>
                    <button
                      class="btn btn-link btn-sm text-nowrap"
                      @click="copyURL(getURL(room), 'host', room.id)"
                    >
                      <span v-if="hostLinkCopied == room.id">copied!</span>
                      <span v-else> Copy </span>
                    </button>
                  </div>
                </div>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">
                  Participant Link (To Share):
                </b>
                <div v-if="isExpired(room)">
                  <span class="text-danger"> Room Expired </span>
                </div>
                <div
                  v-else
                  class="btn btn-outline-blue text-nowrap text-truncate"
                  @click="
                    copyURL(getURL(room, 'participant'), 'participant', room.id)
                  "
                >
                  <span v-if="participantLinkCopied == room.id">copied!</span>
                  <span v-else> Copy Participant Link</span>
                </div>
              </td>
              <td
                v-if="loggedInUser.isAdmin || room.host.id == loggedInUser.id"
              >
                <b class="mobile-tablet-only mr-2">Room Recordings: </b>
                <span>
                  <router-link
                    :to="{
                      name: 'rooms.recordings',
                      params: { id: room.id },
                      query: { roomName: room.room_name },
                    }"
                    class="btn btn-outline-blue text-nowrap"
                  >
                    Navigate to Recordings
                  </router-link>
                </span>
              </td>
              <td v-if="loggedInUser.isAdmin">
                <b class="mobile-tablet-only mr-2">Created By: </b>
                {{ room.host.name }}
              </td>
              <td class="td-actions">
                <button
                  class="btn btn-outline-danger"
                  @click="deleteRoomHelper(room.id)"
                >
                  <span
                    v-if="deleteRoomLoading == room.id"
                    class="fa fa-spin fa-circle-notch"
                  ></span>
                  <span v-else> <icon type="trash" /> Delete Room </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";
export default {
  name: "List",
  data() {
    return {
      participantLinkCopied: null,
      hostLinkCopied: null,
      displayMyRoomsOnly: true,
    };
  },
  mounted() {
    this.getRoomsHelper();
  },
  computed: {
    ...mapState({
      rooms: (state) => state.rooms.rooms.data,
      loading: (state) => state.rooms.rooms.loading,
      deleteRoomLoading: (state) => state.rooms.deleteRoomLoading,
      getRoomLoading: (state) => state.rooms.getRoomLoading,
      loggedInUser: (state) => state.auth.user,
    }),
    shouldDisplayMyRoomsFilter() {
      return this.loggedInUser?.isAdmin;
    },
  },
  methods: {
    ...mapActions({
      getRooms: "rooms/getRooms",
      deleteRoom: "rooms/deleteRoom",
      getRoom: "rooms/getRoom",
      updateRooms: "rooms/updateRooms",
    }),
    getRoomsHelper: function () {
      this.getRooms({
        displayMyRoomsOnly: this.displayMyRoomsOnly,
      });
    },
    deleteRoomHelper: function (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this room!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRoom(id).then((res) => {
            if (res) {
              Swal.fire("Deleted!", "Your room has been deleted.", "success");
              this.getRoomsHelper();
            }
          });
        }
      });
    },
    prettyDate: function (date, onlyDate = false) {
      if (onlyDate) {
        return dayjs(date).format("MMM DD, YYYY");
      } else {
        return dayjs(date).format("MMM DD, YYYY  hh:mm A");
      }
    },
    getMeetingHostURL: function (meeting_id) {
      this.getRoom({
        roomId: meeting_id,
      }).then((res) => {
        if (res) {
          this.updateRooms(res.data.data);
        }
      });
    },
    copyURL: function (url, type = "participant", room_id) {
      const vm = this;
      if (type == "participant") {
        this.participantLinkCopied = room_id;
      } else {
        this.hostLinkCopied = room_id;
      }
      navigator.clipboard.writeText(url).then(
        function () {
          setTimeout(() => {
            if (type == "participant") {
              vm.participantLinkCopied = null;
            } else {
              vm.hostLinkCopied = null;
            }
          }, 700);
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    getURL: function (room, type) {
      if (type == "participant") {
        return `${window.location.origin}/rooms${room.room_slug}?roomName=${room.room_name}`;
      } else {
        return `${window.location.origin}/rooms${room.room_slug}?roomKey=${room.host_link_key}&roomName=${room.room_name}`;
      }
    },
    getRoomKeyQueryParameter: function (roomURL) {
      if (!roomURL) {
        return null;
      }
      const url = new URL(roomURL);
      return url.searchParams.get("roomKey");
    },
    isExpired: function (room) {
      const endDate = dayjs(room.end_date);
      const currentDate = dayjs();
      return endDate.isBefore(currentDate);
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.9rem;
}
</style>
