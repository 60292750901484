<template>
  <div class="container">
    <div class="row">
      <div class="col-6 col-md-8">
        <h1>Meeting Rooms</h1>
      </div>
      <div class="col text-right">
        <router-link
          :to="{ name: 'rooms.all-recordings' }"
          class="btn btn-outline-blue btn-sm"
        >
          <icon type="camera" /> All Recordings
        </router-link>
        <router-link
          :to="{ name: 'rooms.create' }"
          class="btn btn-black btn-sm mx-md-2 my-2"
        >
          <icon type="plus" /> Create New Room
        </router-link>
      </div>
    </div>
    <list />
  </div>
</template>
<script>
import List from "./List";
export default {
  components: {
    List,
  },
};
</script>
